import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ContactSection from "../../components/ContactSection/ContactSection";
//designs
// import BigFish from "../../resources/designs/bigfish.png";
import BMS from "../../resources/designs/bms.png";
import MK from "../../resources/designs/mk.png";
import Bike from "../../resources/designs/bike.jpg";
import Resilient from "../../resources/designs/resilient.jpg";
import Resilient2 from "../../resources/designs/resilient2.jpg";
import Shish1 from "../../resources/designs/shish.jpg";
import Phone from "../../resources/designs/phone.jpg";
import Skin from "../../resources/designs/skin.jpg";

// import MunchDen from "../../resources/designs/munchden.png";
//Posters
import BFOpening from "../../resources/posters/bigfish.jpg";
import Football from "../../resources/posters/football.jpg";
import Jua from "../../resources/posters/jua-siri.jpg";
import Kevin from "../../resources/posters/kevin.jpg";
import Malindi from "../../resources/posters/malindi.jpg";
import Mothers from "../../resources/posters/mothers.jpg";
import PJS from "../../resources/posters/pjs.jpg";
//others
import Bar from "../../resources/posters/bar.jpg";
import Menu from "../../resources/posters/menu.jpg";
import RateCard from "../../resources/posters/ratecard.jpg";
import Shish from "../../resources/posters/shish.jpg";
import Wednesday from "../../resources/posters/wednesday.jpg";
import Wedding from "../../resources/posters/wedding.jpg";
import CV1 from "../../resources/posters/CV1.jpg";
import CV2 from "../../resources/posters/CV2.jpg";
import CV3 from "../../resources/posters/CV3.jpg";
import CV4 from "../../resources/posters/CV4.jpg";
import Honey from "../../resources/posters/honey.jpg";
import ID from "../../resources/posters/id.jpg";

// pdf-Portfolio
import PDFButton from "../../components/UiComponents/PDFDownload";

export default function index() {
  return (
    <MainDiv>
      <Title>Work Samples</Title>
      <PDFButton />
      <TypeDiv>
        <CarouselDiv>
          <Carousel
            infiniteLoop
            showIndicators={false}
            showStatus={false}
            autoPlay
            showArrows={false}
            showThumbs={false}
          >
            <ItemDiv>
              <img src={BMS} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={MK} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Bike} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Resilient} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Resilient2} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Shish1} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Phone} alt="" />
            </ItemDiv>
            <ItemDiv>
              <img src={Skin} alt="" />
            </ItemDiv>
          </Carousel>
        </CarouselDiv>
        <TitleDiv>
          <Title>Logo Designs</Title>
        </TitleDiv>
      </TypeDiv>
      <hr />
      <TypeDiv>
        <TitleDiv>
          <Title>Posters</Title>
        </TitleDiv>
        <CarouselDiv>
          <Carousel
            infiniteLoop
            showIndicators={false}
            showStatus={false}
            autoPlay
            showArrows={false}
            showThumbs={false}
          >
            <PosterDiv>
              <img src={BFOpening} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Football} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Jua} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Kevin} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Malindi} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Mothers} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={PJS} alt="" />
            </PosterDiv>
          </Carousel>
        </CarouselDiv>
      </TypeDiv>
      <TypeDiv>
        <CarouselDiv>
          <Carousel
            infiniteLoop
            showIndicators={false}
            showStatus={false}
            autoPlay
            showArrows={false}
            showThumbs={false}
          >
            <PosterDiv>
              <img src={Bar} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Menu} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={RateCard} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Shish} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Malindi} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Wedding} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Wednesday} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={CV1} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={CV2} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={CV3} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={CV4} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={Honey} alt="" />
            </PosterDiv>
            <PosterDiv>
              <img src={ID} alt="" />
            </PosterDiv>
          </Carousel>
        </CarouselDiv>
        <TitleDiv>
          <Title>Others</Title>
        </TitleDiv>
      </TypeDiv>
      <ContactSection />
    </MainDiv>
  );
}

const MainDiv = styled.div`
  padding: 8em 3em 4em 3em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 7em 2em 4em 2em;
  }
`;

const TypeDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 2em;
  margin-bottom: 1.5em;

  @media (max-width: 1000px) {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;

const CarouselDiv = styled.div`
  height: 30%;
  width: 40%;

  @media (max-width: 1000px) {
    width: 80%;
    height: 20%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;

const ItemDiv = styled.div`
  height: 18em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    width: 80%;
    height: 15em;
  }
`;

const PosterDiv = styled.div`
  height: 25em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    width: auto;
    height: 20em;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-size: 2em;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;
