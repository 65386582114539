import React from "react";
import styled from "styled-components";
import { colors } from "../../resources/Themecolors";
import HeroImage from "../../resources/hero-image.png";
import Portfolio from "../../resources/design-portfolio.pdf";

export default function HeroSection() {
  return (
    <MainDiv>
      <TextDiv>
        <LevelUpDiv>
          <LevelUpText>Level up your business</LevelUpText>
          <Line></Line>
        </LevelUpDiv>
        <IntroText>
          Creative Design Agency.{" "}
          <span style={{ color: `${colors.color2}` }}>Lenny Arts</span>
        </IntroText>
        <IntroDescription>
          We are a creative agency that focuses on graphic design and visuals to
          help you get your audience engagement right and propel your business
          to scalable heights.
        </IntroDescription>
        <ButtonsDiv>
          <Button as="a" href="#contact">
            Contact Us
          </Button>
          <Button
            as="a"
            href={Portfolio}
            Download
            target="_blank"
            rel="noopener norefer"
          >
            Download Portfolio
          </Button>
        </ButtonsDiv>
      </TextDiv>
      <ImageDiv>
        <Image src={HeroImage}></Image>
      </ImageDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 10em;
  padding-bottom: 10em;

  @media (max-width: 800px) {
    padding-top: 0.3em;
    padding-bottom: 1em;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;

const ImageDiv = styled.div`
  height: 25em;
  width: 40em;
  display: flex;
  align-items: flex-start;
  margin-bottom: 3em;
  right: 5px;
  position: absolute;
  @media (max-width: 870px) {
    display: none;
  }
`;

const Image = styled.img`
  @media (max-width: 870px) {
    display: none;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 70%;
  z-index: 2;
  position: absolute;

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 800px) {
    z-index: 0;
    position: relative;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
`;

const LevelUpDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const LevelUpText = styled.p`
  font-family: Poppins;
  font-weight: 300;

  @media (max-width: 400px) {
  }
`;

const Line = styled.hr`
  width: 20em;
  margin-left: 2em;
  border-width: 0.15em;

  @media (max-width: 900px) {
    width: 10em;
  }

  @media (max-width: 400px) {
    width: 5em;
    margin-left: 1em;
  }
`;

const IntroText = styled.h2`
  font-size: 3.6em;
  font-family: Poppins;
  font-weight: 750;
  line-height: 1.2;
  margin-top: 0.3em;
  width: 90%;

  @media (max-width: 600px) {
    font-size: 3em;
    line-height: 1.2;
    margin-top: 0.3em;
    width: 90%;
  }
`;

const IntroDescription = styled.p`
  width: 60%;
  margin-top: 1em;
  font-size: 1.1em;
  font-family: Poppins;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Button = styled.button`
  margin-top: 2em;
  height: 2.8em;
  width: 11em;
  background-color: ${colors.color2};
  font-size: 1.1em;
  font-weight: 600;
  color: ${colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;

  :hover {
    background-color: ${colors.color1};
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
`;
