import React from "react";
import styled from "styled-components";
import { colors } from "../../resources/Themecolors";
import Designer from "../../resources/designer.png";

export default function WhyUsSection() {
  return (
    <MainDiv>
      <ImageDiv>
        <Image src={Designer} alt="lenny arts design work"></Image>
      </ImageDiv>
      <TextDiv>
        <Title>Why Us?</Title>
        <Description>
          <ul>
            <li>
              <Text>We are solution based</Text>– our designs aim at solving
              problems.
            </li>
            <li>
              <Text>We are creative</Text> – that is our flex! Not only do we
              provide graphic solutions for businesses, we do it in a creative
              approach.
            </li>
            <li>
              <Text>Timely</Text> – we have a fast turn around time on all our
              services.
            </li>
            <li>
              <Text>Great customer service</Text> – We have a friendly team that
              is always at the beck of your call.
            </li>
          </ul>
        </Description>
        <Button as="a" href="#contact">
          Hire Us
        </Button>
      </TextDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  margin-top: 5em;
  margin-left: 2em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-flow: column nowrap;
    margin-left: 0em;
  }
`;

const ImageDiv = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 70%;
  width: 70%;
`;

const TextDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: left;
  justify-content: center;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    align-items: center;
    margin-top: 2em;
  }
`;

const Title = styled.h2`
  font-size: 1.9em;
  font-weight: 700;
`;

const Description = styled.p`
  text-align: left;
  width: 80%;
  margin-top: 1em;
  font-weight: 350;

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
`;

const Button = styled.button`
  margin-top: 2em;
  height: 2.5em;
  width: 8em;
  background-color: ${colors.color2};
  font-size: 1.1em;
  font-weight: 600;
  color: ${colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${colors.color1};
  }
`;

const Text = styled.h2`
  font-weight: 500;
`;
