import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import WorkSection from "./pages/WorkSamples";
// import styled from "styled-components";

class App extends Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/work-samples" component={WorkSection} />
          <Route path="/about-us" component={AboutUs} />
        </Switch>
        <Footer />
        {/* WhatsApp icon */}
        <a
          href="https://wa.me/254769511010"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      </Router>
    );
  }
}

export default App;
