import React from "react";
import styled from "styled-components";
import { colors } from "../../resources/Themecolors";
import CountUp from "react-countup";
import Experience from "../../resources/practice.png";
import Client from "../../resources/clients.png";
import Project from "../../resources/projects.png";
import BigFish from "../../resources/clients/bigfish.png";
import BMS from "../../resources/clients/bms.png";
// import Drey from "../../resources/clients/drey.png";
import MK from "../../resources/clients/mk.png";
import MunchDen from "../../resources/clients/munchden.png";
// import RK from "../../resources/clients/rk.png";

export default function WorkSection() {
  return (
    <MainDiv>
      <DetailsDiv>
        <InfoDiv>
          <InfoIcon src={Experience}></InfoIcon>
          <InfoNumber>
            <CountUp end={3} duration={10} />+
          </InfoNumber>
          <InfoText>Years of Experience</InfoText>
        </InfoDiv>
        <InfoDiv top>
          <InfoIcon src={Client}></InfoIcon>
          <InfoNumber>
            <CountUp end={300} duration={10} />+
          </InfoNumber>
          <InfoText>Satisfied Clients</InfoText>
        </InfoDiv>
        <InfoDiv>
          <InfoIcon src={Project}></InfoIcon>
          <InfoNumber>
            <CountUp end={250} duration={10} />+
          </InfoNumber>
          <InfoText>Projects Completed</InfoText>
        </InfoDiv>
      </DetailsDiv>
      <MainClientsDiv>
        <InfoNumber>Some of Our Clients</InfoNumber>
        <ClientsDiv>
          <img src={BigFish} alt=""></img>
          <img src={BMS} alt=""></img>
          <img src={MK} alt=""></img>
          <img src={MunchDen} alt=""></img>
        </ClientsDiv>
      </MainClientsDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  margin-top: 8em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    flex-flow: column nowrap;
  }
`;

// const TitleDiv = styled.div`
//   display: flex;
//   flex-flow: column nowrap;
//   align-items: flex-start;
//   justify-content: center;

//   @media (max-width: 400px) {
//     align-items: center;
//   }
// `;

// const Title = styled.h2`
//   font-size: 2em;
//   font-weight: 700;
// `;

// const Description = styled.p`
//   text-align: left;
//   width: 75%;
//   margin-top: 1em;
//   font-weight: 350;

//   @media (max-width: 400px) {
//     width: 100%;
//     text-align: center;
//   }
// `;

const DetailsDiv = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 600px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    margin-top: 2em;
  }
`;

const InfoDiv = styled.div`
  height: 13em;
  width: 13em;
  margin-top: ${(props) => (props.top ? "1.5em" : "0")};
  border-radius: 50%;
  background: linear-gradient(225deg, #e6e6e6, #ffffff);
  box-shadow: -29px 29px 55px #f2f2f2, 29px -29px 55px #ffffff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    height: 11em;
    width: 11em;
  }
`;

const InfoIcon = styled.img`
  height: 3.5em;
  width: 3.5em;

  @media (max-width: 400px) {
    height: 2.5em;
    width: 2.5em;
    margin-top: -0.4em;
  }
`;

const InfoNumber = styled.h3`
  color: ${colors.color2};
  font-size: 1.4em;
  font-weight: 800;
  margin-top: 0.5em;

  @media (max-width: 400px) {
    font-size: 1.2em;
  }
`;

const InfoText = styled.p`
  font-size: 1em;
  font-weight: 500;

  @media (max-width: 400px) {
    font-size: 0.9em;
  }
`;

const MainClientsDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
`;

const ClientsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > img {
    height: 12em;
    width: auto;
  }
`;
