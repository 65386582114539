import React, { Component } from "react";
import styled from "styled-components";
import ContactForm from "../UiComponents/ContactForm";
// import Contact from "../../resources/contact.png";
import Whatsapp from "../../resources/icons/whatsapp.png";
import Email from "../../resources/icons/email.png";
import { colors } from "../../resources/Themecolors";
class ContactSection extends Component {
  state = {};
  render() {
    return (
      <MainDiv id="contact">
        <ContactDiv>
          <ContentDiv>
            <IconText href="/">
              <Icon src={Whatsapp}></Icon>
              <Text>0769511010</Text>
            </IconText>
            <IconText href="/">
              <Icon src={Email}></Icon>
              <Text>lennyarts254@gmail.com</Text>
            </IconText>
          </ContentDiv>
        </ContactDiv>
        <ContactForm />
      </MainDiv>
    );
  }
}

export default ContactSection;

const MainDiv = styled.div`
  padding-top: 5em;
  display: flex;
  flex-flow: row nowrap:
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 600px) {
    padding-top: 5em;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;

const ContactDiv = styled.div`
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    margin-bottom: 2em;
  }
`;

const ContentDiv = styled.div`
  margin-top: 1em;
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    margin-top: 0.3em;
  }
`;

const IconText = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7em;
`;

const Icon = styled.img`
  height: 1.7em;
  width: 1.7em;
`;

const Text = styled.p`
  margin-left: 0.4em;
  display: flex;
  color: ${colors.dark};
  font-size: 1.1em;
  font-family: Poppins;
`;

// const ImageDiv = styled.div`
//   right: 0;
//   position: fixed;
// `;

// const Image = styled.img`
//   width: 70%;
//   height: 60%;
// `;
