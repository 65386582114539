import React from "react";
import styled from "styled-components";
import ContactSection from "../../components/ContactSection/ContactSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import WhyUsSection from "../../components/WhyUsSection/WhyUsSection";
import WorkSection from "../../components/WorkSection/WorkSection";

export default function index() {
  return (
    <MainSection>
      <HeroSection />
      <ServicesSection />
      <WhyUsSection />
      <WorkSection />
      <ContactSection />
    </MainSection>
  );
}

const MainSection = styled.div`
  padding: 10em 7em 7em 7em;

  @media (max-width: 800px) {
    padding: 8em 3em 5em 3em;
  }

  @media (max-width: 400px) {
    padding: 8em 1em 5em 1em;
  }
`;
