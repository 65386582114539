import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../resources/Themecolors";
// import { withAlert } from "react-alert";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      messageSent: false,
    };
  }

  render() {
    return (
      <MainDiv>
        <Title>Get In Touch</Title>
        <Description>
          Send us a message and we'll get back to you as soon as we can.
        </Description>
        <Form
          id="contact-form"
          onSubmit={this.handleSubmit.bind(this)}
          method="POST"
        >
          <div>
            <input
              type="text"
              value={this.state.name}
              onChange={this.onNameChange.bind(this)}
              placeholder="name"
            />
          </div>
          <div>
            <input
              type="email"
              aria-describedby="emailHelp"
              value={this.state.email}
              onChange={this.onEmailChange.bind(this)}
              placeholder="email"
            />
          </div>
          <div>
            <textarea
              rows="5"
              value={this.state.message}
              onChange={this.onMessageChange.bind(this)}
              placeholder="write your message here..."
            />
          </div>
          <button type="submit">Send</button>
        </Form>
      </MainDiv>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  // onMessageSent() {
  //   this.props.alert.show("Message sent successfully!", {
  //     timeout: 2000,
  //     type: "success",
  //   });
  // }

  handleSubmit(e) {
    e.preventDefault();

    fetch("https://lennyarts-mail.herokuapp.com/send-mail", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert("Message sent successfully!.");
        } else if (response.status === "fail") {
          alert("Message failed to send.");
        }
      });
  }
}

export default ContactForm;

const MainDiv = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled.h2`
  font-size: 2em;
  font-weight: 600;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 350;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  > div {
    width: 90%;
    > input {
      border: 2px solid ${colors.color2};
      height: 2.5em;
      margin-top: 1em;
      padding-left: 0.5em;
      width: 100%;
      border-radius: 4px;
    }
    > textarea {
      border: 2px solid ${colors.color2};
      width: 100%;
      margin-top: 1em;
      padding-left: 0.5em;
      border-radius: 4px;
    }
  }
  > button {
    height: 2.5em;
    background-color: ${colors.color2};
    color: ${colors.white};
    width: 90%;
    margin-top: 1em;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 2em;
    border: 1px solid ${colors.color2};
  }
`;
