export const colors = {
  color1: "#FF6701",
  color2: "#FEA82F",
  color3: "#FFC288",
  color4: "#FCECDD",
  dark: "#404040",
  white: "#ffffff",
  black: "#000000",
};

// the colors are listed according to the order of prominence. Color1 is more prominent than color4.
