import React from "react";
import styled from "styled-components";
import ServiceCards from "../UiComponents/Cards/ServiceCards";
import Design from "../../resources/design.png";
import Social from "../../resources/social.png";
import Photography from "../../resources/photography.png";
import Web from "../../resources/web.png";

const Services = [
  {
    id: 1,
    image: Design,
    title: "Graphic Design",
    description:
      "Let us design your brand materials. Click below to view our work.",
    buttonText: "View our work",
    linkTo: "/work-samples",
  },
  {
    id: 2,
    image: Social,
    title: "Social Media Mngmt",
    description:
      "Establish a strong brand presence online with our creative content creators.",
    buttonText: "Talk to Us",
    linkTo: "#contact",
  },
  {
    id: 3,
    image: Photography,
    title: "Photography",
    description:
      "Get a creative photographer to do your brand product shoot or next commercial.",
    buttonText: "Book a Session",
    linkTo: "#contact",
  },
  {
    id: 4,
    image: Web,
    title: "Web Development",
    description:
      "Get your portfolio website ready, whether for you or your business.",
    buttonText: "Talk to Us",
    linkTo: "#contact",
  },
];

export default function ServicesSection() {
  return (
    <MainDiv>
      <TitleDiv>
        <Title>Our Services</Title>
        {/* <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis
          imperdiet proin fermentum leo vel orci.
        </Description> */}
      </TitleDiv>
      <CardsDiv>
        {Services.map((service) => (
          <ServiceCards service={service} />
        ))}
      </CardsDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  margin-top: 7em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    margin-top: 5em;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: 1.8em;
  font-weight: 700;
`;

// const Description = styled.p`
//   text-align: center;
//   width: 75%;
//   margin-top: 1em;
//   font-weight: 350;

//   @media (max-width: 400px) {
//     width: 100%;
//   }
// `;

const CardsDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 900px) {
    flex-flow: row wrap;
  }
`;
