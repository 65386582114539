import React from "react";
import styled from "styled-components";
import Leonard from "../../resources/leonard.jpeg";
import Kibet from "../../resources/kibet.jpeg";
import Victor from "../../resources/victor.jpeg";
import Kibuika from "../../resources/kibuika.png";

export default function index() {
  return (
    <MainDiv>
      <DetailsDiv>
        <Title>About LennyArts</Title>
        <Description>
          Lenny Arts is a graphic design company that was originally founded in
          2019 by designer, Brian Leonard. The goal has always been to help
          other business grow and establish a strong brand presence by aiding in
          matters visuals. As a brand online and even physically you are judged
          by your appearance. Having this in mind, the way your brand appears,
          from the logo to the content and brand colors used is of importance.
          Our focus has for a long time been on small and medium size
          businesses. However, with the growth and experience we have
          accumulated, we have been able to scale up and handle even corporate
          and big businesses. This could not have been possible through solo
          working but teamwork. Meet our friendly team here:
        </Description>
      </DetailsDiv>
      <TeamDiv>
        <Title>Meet the Team</Title>
        <AvatarsDiv>
          <PersonDiv>
            <ImageDiv>
              <Image src={Leonard} alt=""></Image>
            </ImageDiv>
            <TextDiv>
              <PersonName>Brian Leonard</PersonName>
              <PersonTitle>Founder &amp; Designer</PersonTitle>
            </TextDiv>
          </PersonDiv>
          <PersonDiv>
            <ImageDiv>
              <Image src={Kibet} alt=""></Image>
            </ImageDiv>
            <TextDiv>
              <PersonName>Kibet Tuen</PersonName>
              <PersonTitle>Social Media Manager</PersonTitle>
            </TextDiv>
          </PersonDiv>
          <PersonDiv>
            <ImageDiv>
              <Image src={Victor} alt=""></Image>
            </ImageDiv>
            <TextDiv>
              <PersonName>Victor Kipkorir</PersonName>
              <PersonTitle>Content Curator</PersonTitle>
            </TextDiv>
          </PersonDiv>
        </AvatarsDiv>
      </TeamDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  padding: 9em 7em 5em 7em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 7em 2em 4em 2em;
  }
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${(props) => (props.small ? "1.4em" : "1.7em")};
  font-weight: ${(props) => (props.big ? "700" : "500")};
`;

const Description = styled.p`
  font-size: 1.1em;
  font-weight: 330;
  margin-top: 1em;
  text-align: center;
`;

const TeamDiv = styled.div`
  margin-top: 5em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const AvatarsDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 1.5em;
`;

const PersonDiv = styled.div`
  margin: 0.8em;
`;
const ImageDiv = styled.div`
  height: 12em;
  width: 15em;
  border-radius: 11px;
  background: #ffffff;
  box-shadow: -6px -6px 72px #ebebeb, 6px 6px 72px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    box-shadow: -6px -6px 72px #fcecdd, 6px 6px 72px #fcecdd;
  }
`;

const Image = styled.img`
  background-color: #ffffff;
  height: ${(props) => (props.small ? "75%" : "95%")};
`;

const TextDiv = styled.div`
  margin-top: 0.3em;
  padding-left: 0.3em;
`;

const PersonName = styled.h3``;

const PersonTitle = styled.p`
  font-weight: 250;
`;
