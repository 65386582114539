import React from "react";
import styled from "styled-components";
import { colors } from "../../../resources/Themecolors";
import Portfolio from "../../../resources/design-portfolio.pdf";

export default function index() {
  return (
    <Button
      as="a"
      href={Portfolio}
      Download
      target="_blank"
      rel="noopener norefer"
    >
      Download Portfolio
    </Button>
  );
}

const Button = styled.button`
  height: 2.6em;
  width: 11em;
  background-color: #e0dada;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.color1};
  margin-left: 1em;
  border-radius: 5px;
`;
