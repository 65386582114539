import React from "react";
import styled from "styled-components";
import { colors } from "../../../resources/Themecolors";
import RightArrow from "../../../resources/arrow.png";

export default function ServiceCards({ service }) {
  return (
    <Contact href={service.linkTo}>
      <MainDiv>
        <Image src={service.image} alt="lenny arts design services"></Image>
        <Title>{service.title}</Title>
        <Description>{service.description}</Description>
        <ContactDiv>
          <Contact1 href={service.linkTo}>{service.buttonText}</Contact1>
          <Arrow src={RightArrow} alt="contact arrow"></Arrow>
        </ContactDiv>
      </MainDiv>
    </Contact>
  );
}

const MainDiv = styled.div`
  height: 20em;
  width: 15em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  border-radius: 10px;
  background: white;
  margin: 1em;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: -7px 7px 99px #e6e6e6, 7px -7px 99px #ffffff;
  cursor: pointer;
  :hover {
    transition: all 0.5s ease;
    border-radius: 15px;
    background: white;
    box-shadow: 5px 5px 37px #ffe6c2, -5px -5px 37px #ffffff;

    > h3 {
      transition: all 0.5s ease;
      color: ${colors.color2};
    }

    > div > a {
      transition: all 0.5s ease;
      color: ${colors.dark};
    }
  }
`;

const Image = styled.img`
  height: 4em;
  width: 4em;
  margin-top: -1em;
`;

const Title = styled.h3`
  margin-top: 1em;
  font-size: 1.1em;
  padding: 0em 0.5em 0em 0.5em;
  font-weight: 600;
`;

const Description = styled.p`
  padding: 0.5em 1em 0.5em 1em;
  font-size: 0.8em;
  text-align: center;
  font-weight: 300;
`;

const ContactDiv = styled.div`
  margin-top: 2em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const Contact = styled.a``;

const Contact1 = styled.a`
  color: ${colors.color2};
`;

const Arrow = styled.img`
  height: 1.5em;
  width: 1.5em;
  margin-left: 1em;
`;
