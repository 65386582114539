import React from "react";
import styled from "styled-components";
import { colors } from "../resources/Themecolors";
import Twitter from "../resources/icons/twitter.png";
import Instagram from "../resources/icons/instagram.png";
import Whatsapp from "../resources/icons/whatsapp.png";
import Email from "../resources/icons/email.png";
import MainLogo from "../resources/logo.png";

export default function Footer() {
  return (
    <div>
      <MainDiv>
        <FooterDiv>
          <LogoDiv>
            <Logo src={MainLogo}></Logo>
            <LogoDescription>
              We are a creative agency that focuses on graphic design and
              visuals to help you get your audience engagement right and propel
              your business to scalable heights.
            </LogoDescription>
          </LogoDiv>
          <FooterContent>
            <TitleDiv>
              <Title>Socials</Title>
            </TitleDiv>
            <ContentDiv>
              <IconText
                href="https://twitter.com/Lennyarts_"
                target="_blank"
                rel="no referrer"
              >
                <Icon src={Twitter}></Icon>
              </IconText>
              <IconText
                href="https://www.instagram.com/lennyarts__/"
                target="_blank"
                rel="no referrer"
              >
                <Icon src={Instagram}></Icon>
              </IconText>
            </ContentDiv>
          </FooterContent>
          <FooterContent>
            <TitleDiv>
              <Title>Contacts</Title>
            </TitleDiv>
            <ContentDiv>
              <IconText href="/">
                <Icon src={Whatsapp}></Icon>
                <Text>0769511010</Text>
              </IconText>
              <IconText href="/">
                <Icon src={Email}></Icon>
                <Text>lennyarts254@gmail.com</Text>
              </IconText>
            </ContentDiv>
          </FooterContent>
        </FooterDiv>
        <CopyrightDiv>
          <CopyrightText>
            &copy;Lenny Arts 2021. All Rights Reserved. Built by <a href="tel:+254740459940">Steve Kibuika</a>
          </CopyrightText>
        </CopyrightDiv>
      </MainDiv>
    </div>
  );
}

const MainDiv = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colors.black};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const FooterDiv = styled.div`
  height: auto;
  width: 80%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2em 2em 5em 2em;

  @media (max-width: 500px) {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 2em 2em 3em 2em;
    width: 100%;
  }
`;

const LogoDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 60vw;

  @media (max-width: 500px) {
    align-items: center;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 4em;
  width: 4em;
`;

const LogoDescription = styled.p`
  margin-top: 1em;
  width: 80%;
  color: ${colors.white};
  font-family: Poppins;
  font-size: 0.85em;

  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`;

const FooterContent = styled.div`
  width: 30%;
  @media (min-width: 850px) {
    height: 30vh;
  }

  @media (max-width: 500px) {
    margin-top: 2em;
  }
`;

const TitleDiv = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 650;
  letter-spacing: 1.2px;

  @media (max-width: 500px) {
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled.div`
  color: ${colors.white};
  font-size: 1em;
  font-family: Poppins;
`;

const ContentDiv = styled.div`
  margin-top: 1em;
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  @media (max-width: 500px) {
    align-items: center;
    justify-content: center;
    margin-top: 0.3em;
  }
`;

const IconText = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7em;
`;

const Icon = styled.img`
  height: 1.7em;
  width: 1.7em;
`;

const Text = styled.p`
  margin-left: 0.4em;
  display: flex;
  color: ${colors.white};
  font-size: 0.9em;
  font-family: Poppins;
`;

const CopyrightDiv = styled.div`
  width: 100%;
  background-color: ${colors.color2};
  padding: 0.5em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const CopyrightText = styled.p`
  font-family: Poppins;
  float: right;
  font-size: 0.85em;
`;
